var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "d-flex justify-space-between" },
      [
        _c("text-field-label", { attrs: { label: _vm.text } }),
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c(
              "div",
              { staticClass: "mr-2 mt-3" },
              [
                _vm.loading
                  ? _c("v-progress-circular", {
                      attrs: { size: "16", width: "2", indeterminate: "" }
                    })
                  : _c(
                      "transition",
                      { attrs: { name: "fade" } },
                      [
                        _c("v-img", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.icon && !_vm.isTimeout,
                              expression: "icon && !isTimeout"
                            }
                          ],
                          attrs: {
                            src: _vm.icon,
                            height: "16",
                            eager: "",
                            contain: ""
                          }
                        })
                      ],
                      1
                    )
              ],
              1
            ),
            _c("v-select", {
              staticClass: "mt-0",
              staticStyle: { "max-width": "300px" },
              attrs: {
                value: _vm.value,
                items: _vm.items,
                "item-text": "description",
                "item-value": "name",
                attach: "",
                dense: "",
                outlined: "",
                "hide-details": ""
              },
              on: {
                input: function($event) {
                  return _vm.updateOrderSelection($event)
                }
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }