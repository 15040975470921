var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order" },
    [
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c("header-label", {
            attrs: {
              label: _vm.$t("order.header"),
              "prepend-icon": "$orderHeader"
            }
          }),
          _c(
            "div",
            { staticClass: "mb-3" },
            [
              _c("v-icon", { attrs: { color: "primary" } }, [
                _vm._v(" mdi-chevron-left ")
              ])
            ],
            1
          ),
          _c("span", { staticClass: "primary--text mb-3" }, [
            _vm._v(" " + _vm._s(_vm.$t("shopsetting.header")) + " ")
          ])
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mb-4", attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            [
              _c("title-label", {
                attrs: {
                  label: _vm.$t("order.title"),
                  "prepend-icon": "$orderTitle"
                }
              }),
              _c("order-toggle", {
                attrs: {
                  text: _vm.$t("order.autoTransferBank"),
                  state: _vm.state.createOrder,
                  css: "pl-12"
                },
                on: {
                  input: function($event) {
                    return _vm.openCreateOrderFromBank()
                  }
                },
                model: {
                  value: _vm.createOrder,
                  callback: function($$v) {
                    _vm.createOrder = $$v
                  },
                  expression: "createOrder"
                }
              }),
              _c(
                "v-row",
                { staticClass: "mt-4", attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-space-around pl-12" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "ml-12 mt-2",
                            class: { "disabled--text": !_vm.createOrder }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("order.paymentMethods")) + " "
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c("v-select", {
                              staticClass: "ml-2",
                              staticStyle: { "max-width": "200px" },
                              attrs: {
                                disabled: !_vm.createOrder,
                                items: _vm.setting.paymentMethods,
                                "item-text": "display_name",
                                "item-value": "value",
                                attach: "",
                                dense: "",
                                outlined: "",
                                "hide-details": ""
                              },
                              on: {
                                input: function($event) {
                                  return _vm.submit("autoTransferBank")
                                }
                              },
                              model: {
                                value: _vm.setting.autoTransferBank,
                                callback: function($$v) {
                                  _vm.$set(_vm.setting, "autoTransferBank", $$v)
                                },
                                expression: "setting.autoTransferBank"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ])
                ],
                1
              ),
              _c("order-toggle", {
                attrs: {
                  text: _vm.$t("order.autoTransfer"),
                  state: _vm.state.autoTransfer,
                  css: "pl-12"
                },
                on: {
                  input: function($event) {
                    return _vm.submit("autoTransfer")
                  }
                },
                model: {
                  value: _vm.setting.autoTransfer,
                  callback: function($$v) {
                    _vm.$set(_vm.setting, "autoTransfer", $$v)
                  },
                  expression: "setting.autoTransfer"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mb-4", attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            [
              _c("title-label", {
                attrs: { label: _vm.$t("order.autoVerifyTitle") }
              }),
              _vm.setting.remainingAutoVerifyTrial > 0
                ? _c("p", { staticStyle: { "text-indent": "3.5em" } }, [
                    _vm._v(
                      "ทดลองฟรีคงเหลือ " +
                        _vm._s(_vm.setting.remainingAutoVerifyTrial) +
                        "/100 ครั้ง"
                    )
                  ])
                : _vm._e(),
              _c("order-toggle", {
                attrs: {
                  text: _vm.$t("order.autoVerifyPayment"),
                  state: _vm.state.autoVerifyPayment,
                  "text-icon": "$packageTitle"
                },
                on: {
                  input: function($event) {
                    return _vm.submit("autoVerifyPayment")
                  }
                },
                model: {
                  value: _vm.setting.autoVerifyPayment,
                  callback: function($$v) {
                    _vm.$set(_vm.setting, "autoVerifyPayment", $$v)
                  },
                  expression: "setting.autoVerifyPayment"
                }
              }),
              _c(
                "v-row",
                { staticClass: "mt-3", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "pl-12", attrs: { cols: "10" } },
                    [
                      _c("packing-toggle", {
                        attrs: {
                          text: _vm.$t("order.autoVerifyAcceptOverpaid"),
                          state: _vm.state.autoVerifyAcceptOverpaid,
                          disable: _vm.setting.autoVerifyPayment
                        },
                        on: {
                          input: function($event) {
                            return _vm.submit("autoVerifyAcceptOverpaid")
                          }
                        },
                        model: {
                          value: _vm.setting.autoVerifyAcceptOverpaid,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.setting,
                              "autoVerifyAcceptOverpaid",
                              $$v
                            )
                          },
                          expression: "setting.autoVerifyAcceptOverpaid"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-3", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "pl-12", attrs: { cols: "10" } },
                    [
                      _c("packing-toggle", {
                        attrs: {
                          text: _vm.$t("order.autoVerifyAcceptUnderpaid"),
                          state: _vm.state.autoVerifyAcceptUnderpaid,
                          disable: _vm.setting.autoVerifyPayment
                        },
                        on: {
                          input: function($event) {
                            return _vm.submit("autoVerifyAcceptUnderpaid")
                          }
                        },
                        model: {
                          value: _vm.setting.autoVerifyAcceptUnderpaid,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.setting,
                              "autoVerifyAcceptUnderpaid",
                              $$v
                            )
                          },
                          expression: "setting.autoVerifyAcceptUnderpaid"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mb-4", attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            [
              _c("order-toggle", {
                attrs: {
                  text: _vm.$t("order.editProductAfterPay"),
                  state: _vm.state.editProductAfterPay
                },
                on: {
                  input: function($event) {
                    return _vm.submit("editProductAfterPay")
                  }
                },
                model: {
                  value: _vm.setting.editProductAfterPay,
                  callback: function($$v) {
                    _vm.$set(_vm.setting, "editProductAfterPay", $$v)
                  },
                  expression: "setting.editProductAfterPay"
                }
              }),
              _c("order-toggle", {
                attrs: {
                  text: _vm.$t("order.enableHolding"),
                  state: _vm.state.enableHolding
                },
                on: {
                  input: function($event) {
                    return _vm.submit("enableHolding")
                  }
                },
                model: {
                  value: _vm.setting.enableHolding,
                  callback: function($$v) {
                    _vm.$set(_vm.setting, "enableHolding", $$v)
                  },
                  expression: "setting.enableHolding"
                }
              }),
              _c("order-toggle", {
                attrs: {
                  text: _vm.$t("order.posAutoRedirect"),
                  state: _vm.state.posAutoRedirect
                },
                on: {
                  input: function($event) {
                    return _vm.submit("posAutoRedirect")
                  }
                },
                model: {
                  value: _vm.setting.posAutoRedirect,
                  callback: function($$v) {
                    _vm.$set(_vm.setting, "posAutoRedirect", $$v)
                  },
                  expression: "setting.posAutoRedirect"
                }
              }),
              _c("order-toggle", {
                attrs: {
                  text: _vm.$t("order.liveOrderAutoMerge"),
                  state: _vm.state.liveOrderAutoMerge
                },
                on: {
                  input: function($event) {
                    return _vm.submit("liveOrderAutoMerge")
                  }
                },
                model: {
                  value: _vm.setting.liveOrderAutoMerge,
                  callback: function($$v) {
                    _vm.$set(_vm.setting, "liveOrderAutoMerge", $$v)
                  },
                  expression: "setting.liveOrderAutoMerge"
                }
              }),
              _c("order-toggle", {
                attrs: {
                  text: _vm.$t("order.changePrint"),
                  state: _vm.state.changePrint
                },
                on: {
                  input: function($event) {
                    return _vm.submit("changePrint")
                  }
                },
                model: {
                  value: _vm.setting.changePrint,
                  callback: function($$v) {
                    _vm.$set(_vm.setting, "changePrint", $$v)
                  },
                  expression: "setting.changePrint"
                }
              }),
              _c("order-toggle", {
                attrs: {
                  text: _vm.$t("order.pendingAllOrders"),
                  state: _vm.state.pendingAllOrders
                },
                on: {
                  input: function($event) {
                    return _vm.submit("pendingAllOrders")
                  }
                },
                model: {
                  value: _vm.setting.pendingAllOrders,
                  callback: function($$v) {
                    _vm.$set(_vm.setting, "pendingAllOrders", $$v)
                  },
                  expression: "setting.pendingAllOrders"
                }
              }),
              _c(
                "v-row",
                { staticClass: "mt-4", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "10" } },
                    [
                      _c("order-selection", {
                        attrs: {
                          text: _vm.$t("order.invoiceNumberOption"),
                          state: _vm.state.invoiceNumberOption,
                          items: _vm.invoiceNumberEnum
                        },
                        on: {
                          input: function($event) {
                            return _vm.submit("invoiceNumberOption")
                          }
                        },
                        model: {
                          value: _vm.setting.invoiceNumberOption,
                          callback: function($$v) {
                            _vm.$set(_vm.setting, "invoiceNumberOption", $$v)
                          },
                          expression: "setting.invoiceNumberOption"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  staticClass: "mt-4",
                  attrs: { readonly: _vm.loading, "lazy-validation": "" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submit("preorderLimit")
                    }
                  },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c("v-col", { attrs: { cols: "9" } }, [
                        _c(
                          "div",
                          { staticClass: "d-flex align-center mt-2" },
                          [
                            _c("text-field-label", {
                              attrs: { label: _vm.$t("order.preorderLimit") }
                            }),
                            _vm.edit
                              ? _c("v-text-field", {
                                  staticClass: "ml-2",
                                  staticStyle: { "max-width": "200px" },
                                  attrs: {
                                    dense: "",
                                    outlined: "",
                                    "hide-details": ""
                                  },
                                  model: {
                                    value: _vm.setting.preorderLimit,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.setting,
                                        "preorderLimit",
                                        $$v
                                      )
                                    },
                                    expression: "setting.preorderLimit"
                                  }
                                })
                              : _c(
                                  "span",
                                  { staticClass: "primary--text ml-2" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.setting.preorderLimit) +
                                        " "
                                    )
                                  ]
                                ),
                            _c("span", { staticClass: "ml-2" }, [
                              _vm._v(" " + _vm._s(_vm.$t("order.days")) + " ")
                            ])
                          ],
                          1
                        )
                      ]),
                      _c(
                        "v-col",
                        { staticClass: "d-flex align-center" },
                        [
                          _vm.edit
                            ? _c("save-change-button", {
                                staticClass: "mr-1",
                                attrs: {
                                  state: _vm.state.preorderLimit,
                                  disabled: !_vm.valid,
                                  label: _vm.$t("order.btn.save")
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.submit("preorderLimit")
                                  }
                                }
                              })
                            : _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    rounded: "",
                                    depressed: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openEditOrderAge()
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { icon: "" } }, [
                                    _vm._v(" mdi-square-edit-outline ")
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("order.btn.edit")) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }