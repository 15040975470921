<template>
  <div>
    <div class="d-flex justify-space-between">
      <text-field-label
        :label="text" />
      <div class="d-flex">
        <div class="mr-2 mt-3">
          <v-progress-circular
            v-if="loading"
            size="16"
            width="2"
            indeterminate />
          <transition
            v-else
            name="fade">
            <v-img
              v-show="icon && !isTimeout"
              :src="icon"
              height="16"
              eager
              contain />
          </transition>
        </div>
        <v-select
          :value="value"
          :items="items"
          item-text="description"
          item-value="name"
          style="max-width: 300px;"
          class="mt-0"
          attach
          dense
          outlined
          hide-details
          @input="updateOrderSelection($event)">
        </v-select>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OrderToggle',
  props: {
    text: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    state: {
      type: String,
      validator: (val) => ['ready', 'loading', 'success', 'error'].includes(val),
      default: () => 'ready'
    }
  },
  data () {
    return {
      timeOut: null,
      isTimeout: false
    }
  },
  computed: {
    loading () {
      return this.state === 'loading'
    },
    icon () {
      switch (this.state) {
        case 'success':
          return `${process.env.VUE_APP_ROUTE_PREFIX}imgs/icon/correct_icon.gif?fid=${ Math.random(10000) }`
        case 'error':
          return `${process.env.VUE_APP_ROUTE_PREFIX}imgs/icon/correct_icon.gif?fid=${ Math.random(10000) }`
        default: return null
      }
    },
    isError () {
      return this.state === 'error'
    }
  },
  watch: {
    state (value) {
      if (value === 'success') {
        clearTimeout(this.timeOut)
        this.timeOut = setTimeout(() => {
          this.isTimeout = true
        }, 3000)
      } else {
        this.isTimeout = false
      }
    }
  },
  methods: {
    updateOrderSelection (val) {
      this.$emit('input', val)
    }
  }
}
</script>
