<template>
  <div class="order">
    <div class="d-flex align-center">
      <header-label
        :label="$t('order.header')"
        prepend-icon="$orderHeader" />
      <div class="mb-3">
        <v-icon color="primary">
          mdi-chevron-left
        </v-icon>
      </div>
      <span class="primary--text mb-3">
        {{ $t('shopsetting.header') }}
      </span>
    </div>
    <v-card
      class="mb-4"
      flat>
      <v-card-text>
        <title-label
          :label="$t('order.title')"
          prepend-icon="$orderTitle" />
        <order-toggle
          v-model="createOrder"
          :text="$t('order.autoTransferBank')"
          :state="state.createOrder"
          css="pl-12"
          @input="openCreateOrderFromBank()">
        </order-toggle>
        <v-row
          class="mt-4"
          no-gutters>
          <v-col cols="6">
            <div class="d-flex justify-space-around pl-12">
              <span
                :class="{ 'disabled--text': !createOrder }"
                class="ml-12 mt-2">
                {{ $t('order.paymentMethods') }}
              </span>
              <div class="d-flex">
                <v-select
                  v-model="setting.autoTransferBank"
                  :disabled="!createOrder"
                  :items="setting.paymentMethods"
                  item-text="display_name"
                  item-value="value"
                  style="max-width: 200px;"
                  class="ml-2"
                  attach
                  dense
                  outlined
                  hide-details
                  @input="submit('autoTransferBank')">
                </v-select>
              </div>
            </div>
          </v-col>
        </v-row>
        <order-toggle
          v-model="setting.autoTransfer"
          :text="$t('order.autoTransfer')"
          :state="state.autoTransfer"
          css="pl-12"
          @input="submit('autoTransfer')">
        </order-toggle>
      </v-card-text>
    </v-card>
    <v-card
      class="mb-4"
      flat>
      <v-card-text>
        <title-label
          :label="$t('order.autoVerifyTitle')"/>
          <p v-if="setting.remainingAutoVerifyTrial > 0" style="text-indent: 3.5em;">ทดลองฟรีคงเหลือ {{ setting.remainingAutoVerifyTrial }}/100 ครั้ง</p>
        <order-toggle
          v-model="setting.autoVerifyPayment"
          :text="$t('order.autoVerifyPayment')"
          :state="state.autoVerifyPayment"
          text-icon="$packageTitle"
          @input="submit('autoVerifyPayment')">
        </order-toggle>
        <v-row
          class="mt-3"
          no-gutters>
          <v-col
            cols="10"
            class="pl-12">
            <packing-toggle
              v-model="setting.autoVerifyAcceptOverpaid"
              :text="$t('order.autoVerifyAcceptOverpaid')"
              :state="state.autoVerifyAcceptOverpaid"
              :disable="setting.autoVerifyPayment"
              @input="submit('autoVerifyAcceptOverpaid')">
            </packing-toggle>
          </v-col>
        </v-row>
        <v-row
          class="mt-3"
          no-gutters>
          <v-col
            cols="10"
            class="pl-12"
            >
            <packing-toggle
              v-model="setting.autoVerifyAcceptUnderpaid"
              :text="$t('order.autoVerifyAcceptUnderpaid')"
              :state="state.autoVerifyAcceptUnderpaid"
              :disable="setting.autoVerifyPayment"
              @input="submit('autoVerifyAcceptUnderpaid')">
            </packing-toggle>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
      class="mb-4"
      flat>
      <v-card-text>
        <order-toggle
          v-model="setting.editProductAfterPay"
          :text="$t('order.editProductAfterPay')"
          :state="state.editProductAfterPay"
          @input="submit('editProductAfterPay')">
        </order-toggle>
        <order-toggle
          v-model="setting.enableHolding"
          :text="$t('order.enableHolding')"
          :state="state.enableHolding"
          @input="submit('enableHolding')">
        </order-toggle>
        <order-toggle
          v-model="setting.posAutoRedirect"
          :text="$t('order.posAutoRedirect')"
          :state="state.posAutoRedirect"
          @input="submit('posAutoRedirect')">
        </order-toggle>
        <order-toggle
          v-model="setting.liveOrderAutoMerge"
          :text="$t('order.liveOrderAutoMerge')"
          :state="state.liveOrderAutoMerge"
          @input="submit('liveOrderAutoMerge')">
        </order-toggle>
        <order-toggle
          v-model="setting.changePrint"
          :text="$t('order.changePrint')"
          :state="state.changePrint"
          @input="submit('changePrint')">
        </order-toggle>
        <order-toggle
          v-model="setting.pendingAllOrders"
          :text="$t('order.pendingAllOrders')"
          :state="state.pendingAllOrders"
          @input="submit('pendingAllOrders')">
        </order-toggle>
        <v-row
          class="mt-4"
          no-gutters>
          <v-col cols="10">
            <order-selection
              v-model="setting.invoiceNumberOption"
              :text="$t('order.invoiceNumberOption')"
              :state="state.invoiceNumberOption"
              :items="invoiceNumberEnum"
              @input="submit('invoiceNumberOption')">
            </order-selection>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          :readonly="loading"
          class="mt-4"
          lazy-validation
          @submit.prevent="submit('preorderLimit')">
          <v-row no-gutters>
            <v-col cols="9">
              <div class="d-flex align-center mt-2">
                <text-field-label
                  :label="$t('order.preorderLimit')">
                </text-field-label>
                <v-text-field
                  v-if="edit"
                  v-model="setting.preorderLimit"
                  class="ml-2"
                  style="max-width: 200px;"
                  dense
                  outlined
                  hide-details />
                <span
                  v-else
                  class="primary--text ml-2">
                  {{ setting.preorderLimit }}
                </span>
                <span class="ml-2">
                  {{ $t('order.days') }}
                </span>
              </div>
            </v-col>
            <v-col class="d-flex align-center">
              <!-- <v-btn
              v-if="edit"
              color="primary"
              rounded
              depressed
              @click="submit('preorderLimit')">
              <v-icon icon>
                mdi-content-save-outline
              </v-icon>
              <span>
                {{ $t('order.btn.save') }}
              </span>
            </v-btn> -->
              <save-change-button
                v-if="edit"
                class="mr-1"
                :state="state.preorderLimit"
                :disabled="!valid"
                :label="$t('order.btn.save')"
                @click="submit('preorderLimit')">
              </save-change-button>
              <v-btn
                v-else
                color="primary"
                rounded
                depressed
                @click="openEditOrderAge()">
                <v-icon icon>
                  mdi-square-edit-outline
                </v-icon>
                <span>
                  {{ $t('order.btn.edit') }}
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import OrderToggle from '@/views/ShopSetting/Pages/Order/components/OrderToggle.vue'
import PackingToggle from '@/views/ShopSetting/Pages/Packing/components/PackingToggle.vue'
import OrderSelection from '@/views/ShopSetting/Pages/Order/components/OrderSelection'
import { GET_INVOICENUMBEROPTION_ENUM } from '@/resources/graphql/query/Enum'
import { GET_SETTING_ORDER } from '@/resources/graphql/query/Setting'
import { UPDATE_SETTING } from '@/resources/graphql'

export default {
  name: 'Order',
  apollo: {
    setting: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SETTING_ORDER,
      result () {
        if (this.setting.autoTransferBank) {
          this.createOrder = true
        }
        this.setting.paymentMethods.unshift({
          display_name: 'เลือกธนาคาร',
          value: ''
        })
      }
    }),
    __type: () => ({
      query: GET_INVOICENUMBEROPTION_ENUM,
      result ({ data: { __type } }) {
        this.invoiceNumberEnum = __type.enumValues
      },
      update: (data) => data.__type
    })
  },
  components: {
    OrderToggle,
    PackingToggle,
    OrderSelection
  },
  data () {
    return {
      edit: false,
      createOrder: false,
      setting: {},
      invoiceNumberEnum: [],
      valid: true,
      state: {
        createOrder: 'ready',
        autoTransfer: 'ready',
        editProductAfterPay: 'ready',
        enableHolding: 'ready',
        posAutoRedirect: 'ready',
        liveOrderAutoMerge: 'ready',
        changePrint: 'ready',
        pendingAllOrders: 'ready',
        invoiceNumberOption: 'ready',
        preorderLimit: 'ready',
        autoVerifyPayment: 'ready',
        autoVerifyAcceptOverpaid: 'ready',
        autoVerifyAcceptUnderpaid: 'ready'
      },
      autoTransferBankState: 'ready'
    }
  },
  computed: {
    loading () {
      return this.state.preorderLimit === 'loading'
    }
  },
  methods: {
    openEditOrderAge () {
      this.edit = true
    },
    openCreateOrderFromBank () {
      this.state.createOrder = 'loading'
      if (this.createOrder) {
        this.submit('autoTransferBank')
        this.state.createOrder = 'success'
      } else {
        this.setting.autoTransferBank = ''
        this.submit('autoTransferBank')
        this.state.createOrder = 'success'
      }
    },
    async submit (field) {
      this.state[field] = 'loading'
      if (field === 'preorderLimit') {
        this.setting[field] = Number(this.setting[field])
      }
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            [field]: this.setting[field]
          }
        }
      }).catch(() => {})
      if (res) {
        const { data: { updateSetting } } = res
        if (updateSetting.errors && Object.keys(updateSetting.errors).length) {
          this.state[field] = 'error'
        } else {
          this.state[field] = 'success'
        }
        if (field === 'preorderLimit') {
          setTimeout(() => {
            this.state.preorderLimit = 'ready'
            this.edit = false
          }, 1000)
        }
      }
    }
  }
}
</script>
