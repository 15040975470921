<template>
  <div>
    <v-row
      class="mt-3"
      no-gutters>
      <v-col cols="10">
        <div class="d-flex justify-space-between">
          <div :class="css">
            <text-field-label
              :label="text" />
          </div>
          <div class="d-flex">
            <div
              :class="{ 'mr-2 mt-2' : !loading }"
              class="mr-2 mt-1">
              <v-progress-circular
                v-if="loading"
                size="16"
                width="2"
                indeterminate />
              <transition
                v-else
                name="fade">
                <v-img
                  v-show="icon && !isTimeout"
                  :src="icon"
                  height="16"
                  eager
                  contain />
              </transition>
            </div>
            <v-switch
              :input-value="value"
              :disabled="loading"
              class="mt-0 ml-1"
              inset
              hide-details
              v-on="$listeners"
              @change="$emit('input', $event)">
            </v-switch>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Boolean,
      default: () => false
    },
    state: {
      type: String,
      validator: (val) => ['ready', 'loading', 'success', 'error'].includes(val),
      default: () => 'ready'
    },
    text: {
      type: String,
      default: () => ''
    },
    css: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      timeOut: null,
      isTimeout: false
    }
  },
  computed: {
    loading () {
      return this.state === 'loading'
    },
    icon () {
      switch (this.state) {
        case 'success':
          return `${process.env.VUE_APP_ROUTE_PREFIX}imgs/icon/correct_icon.gif?fid=${ Math.random(10000) }`
        case 'error':
          return `${process.env.VUE_APP_ROUTE_PREFIX}imgs/icon/correct_icon.gif?fid=${ Math.random(10000) }`
        default: return null
      }
    },
    isError () {
      return this.state === 'error'
    },
    color () {
      switch (this.state) {
        case 'ready':
        case 'loading':
          return 'primary'
        case 'success':
          return 'success'
        case 'error':
          return 'error'
        default: return 'primary'
      }
    }
  },
  watch: {
    state (value) {
      if (value === 'success') {
        clearTimeout(this.timeOut)
        this.timeOut = setTimeout(() => {
          this.isTimeout = true
        }, 3000)
      } else {
        this.isTimeout = false
      }
    }
  }
}
</script>

<style>

</style>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
